import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { Close } from "@material-ui/icons";
import Login from "../user/login/Login";
import { isLoggedIn, getUser } from "../../utils/auth";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
  },
  DialogContent: {
    padding: theme.spacing(10, 6),
  },
  loginTitle: {
    textAlign: "center",
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
  },

  closeButton: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
    color: theme.palette.grey[500],
    zIndex: 1,
  },
}));

function LoginDialog(props) {
  const classes = useStyles();

  const handleClose = () => {
    props.setLogin(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Dialog
        open={props.login}
        onClose={handleClose}
        // aria-labelledby="form-dialog-title"
        // disableBackdropClick
        // disableEscapeKeyDown
      >
        <IconButton className={classes.closeButton} onClick={handleClose}>
          <Close />
        </IconButton>

        <DialogContent
          classes={{
            root: classes.DialogContent,
          }}
        >
          {isLoggedIn() ? (
            <div>
              <Typography
                className={classes.loginTitle}
                component="h2"
                variant="h5"
              >
                Chào mừng {getUser().displayName}
              </Typography>
              <DialogContentText>
                Cùng học lập trình tại World IT Dojo!
              </DialogContentText>
            </div>
          ) : (
            <div>
              <Typography
                className={classes.loginTitle}
                component="h2"
                variant="h5"
              >
                Đăng nhập vào World IT Dojo
              </Typography>
              <Login />
              <DialogContentText>
                Cùng học lập trình tại World IT Dojo!
              </DialogContentText>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default LoginDialog;
