import React from "react";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import ALink from "../common/Alink";

import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Button from "@material-ui/core/Button";

import Logo from "../../media/common/world-logo.png";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { Close } from "@material-ui/icons";
import Login from "../user/login/Login";

import Avatar from "@material-ui/core/Avatar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import { getUser, logout } from "../../utils/auth";
import getFirebase from "../../utils/firebase";
import LoginDialog from "../common/LoginDialog";

const drawerWidth = 200;
const firebase = getFirebase();

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
  },
  appBar: {
    background: "rgb(96,159,254)",
    background:
      "linear-gradient(90deg, rgba(96,159,254,1) 0%, rgba(0,77,170,1) 100%)",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  SingInButton: {
    textTransform: "none",
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(0, 5),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  NaviLogo: {
    // flexGrow: 1,
    textDecoration: "none",
    // "&:focus": "none",
  },
  NaniSpace: {
    flexGrow: 1,
  },
  logo: {
    marginTop: 5,
    width: 70,
  },
  languageButtonText: {
    fontFamily: "'Roboto Condensed', sans-serif",
  },
  DialogContent: {
    padding: theme.spacing(10, 6),
  },
  loginTitle: {
    textAlign: "center",
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  closeButton: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
    color: theme.palette.grey[500],
    zIndex: 1,
  },
  link: {
    textDecoration: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:active": {
      color: "#fff",
    },
  },
}));

const StyledMenu = withStyles({
  paper: {
    boxShadow:
      "0 2px 10px 0 rgba(0, 0, 0, 0.18), 0 2px 10px 0 rgba(0, 0, 0, 0.15)",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "& .MuiListItemIcon-root": {
      color: theme.palette.primary.main,
      minWidth: theme.spacing(6),
    },
    "& .MuiListItemText-primary": {
      color: theme.palette.grey[800],
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

function Navigation(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [login, setLogin] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClickOpen = () => {
    setLogin(true);
  };
  const handleClose = () => {
    setLogin(false);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleAvatarOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleAvatarClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout(firebase).then(() => {
      setAnchorEl(null);
    });
  };

  function isAuthenticated() {
    return props.firebaseCreated && firebase.auth().currentUser;
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <ALink className={classes.NaviLogo} to="/">
            <img src={Logo} alt="Logo" className={classes.logo} />
          </ALink>
          <div className={classes.NaniSpace}></div>
          <div style={props.firebaseCreated ? {} : { display: "none" }}>
            <div style={isAuthenticated() ? {} : { display: "none" }}>
              <IconButton onClick={handleAvatarOpen}>
                <Avatar src={getUser().photoURL} />
              </IconButton>
              <StyledMenu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleAvatarClose}
              >
                <StyledMenuItem onClick={handleAvatarClose}>
                  <ALink className={classes.link} to="/app/user">
                    <ListItemIcon>
                      <PersonPinIcon />
                    </ListItemIcon>
                    <ListItemText primary="Tài Khoản" />
                  </ALink>
                </StyledMenuItem>
                <StyledMenuItem onClick={handleLogout}>
                  <ALink className={classes.link} to="/home">
                    <ListItemIcon>
                      <ExitToAppIcon />
                    </ListItemIcon>
                    <ListItemText primary="Thoát" />
                  </ALink>
                </StyledMenuItem>
              </StyledMenu>
            </div>
            <div style={isAuthenticated() ? { display: "none" } : {}}>
              <Button
                edge="start"
                className={classes.SingInButton}
                color="inherit"
                aria-label="menu"
                onClick={handleClickOpen}
              >
                Đăng nhập
              </Button>
            </div>
          </div>
        </Toolbar>
      </AppBar>

      <LoginDialog login={login} setLogin={setLogin} />

      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          <ListItem
            button
            component={ALink}
            to="/home"
            onClick={handleDrawerClose}
          >
            <ListItemText primary="Trang chủ" />
          </ListItem>

          <ListItem button component={ALink} to="/" onClick={handleDrawerClose}>
            <ListItemText primary="Giới thiệu" />
          </ListItem>

          <ListItem
            button
            component={ALink}
            to="/courses"
            onClick={handleDrawerClose}
          >
            <ListItemText primary="Khóa học" />
          </ListItem>

          <ListItem
            button
            component={ALink}
            to="/blog"
            onClick={handleDrawerClose}
          >
            <ListItemText primary="Tin tức" />
          </ListItem>

          <ListItem
            button
            component={ALink}
            to="/help"
            onClick={handleDrawerClose}
          >
            <ListItemText primary="Hỗ trợ" />
          </ListItem>
        </List>
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
      </main>
    </div>
  );
}

export default Navigation;
