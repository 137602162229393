import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Footer from "./Footer";
import Navigation from "./Navigation";
import getFirebase from "../../utils/firebase";

const useStyles = makeStyles(() => ({
  app: {
    minHeight: "100vh",
    position: "relative",
  },
}));

const firebase = getFirebase();
const Layout = (props) => {
  const [firebaseCreated, setFirebaseCreated] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    firebase &&
      firebase.auth().onAuthStateChanged(function (user) {
        setFirebaseCreated(true);
      });
  }, [firebase]);

  return (
    <div className={classes.app}>
      <Navigation firebaseCreated={firebaseCreated} />
      {props.children}
      <Footer />
    </div>
  );
};

export default Layout;
