import React from "react";
import Img from "gatsby-image"

export function getImg(src) {
  const results = src.split("/");
  const imgFileName = results[results.length - 1];
  const category = results[results.length - 2];
  switch (category) {
    case "blog":
    case "help":
    case "learning":
    case "landing-pages":
      return validateUrl(category, imgFileName);
    default:
      return src;
  }
}

function validateUrl(category, imgFileName) {
  let result = require("../../media/" + category + "/" + imgFileName);
  if (typeof result === "string" && result.indexOf("static") !== -1) return result;
  return result.default ? result.default : result;
}

export default function Image(props) {
  if (props.src.childImageSharp) {
    return <Img
      className={props.className}
      id={props.id}
      fluid={props.src.childImageSharp.fluid}
      atl={props.alt}
    />
  } else if (typeof props.src === "string" && props.src.indexOf("static") === -1) {
    return <img
      className={props.className}
      id={props.id}
      src={getImg(props.src)}
      alt={props.alt}
    ></img>
  } else {
    return <img {...props}></img>
  }
}
