import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { Container } from "@material-ui/core";
import Image from "../common/Image";
import Alink from "../common/Alink";
import Logo from "../../media/common/world-logo.png";
import { FaFacebookSquare, FaMapMarker, FaPhone, FaFax } from "react-icons/fa";

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: "#0C1C39",
    position: "absolute",
    width: "100%",
    bottom: 0,
    zIndex: 200,
    boxSizing: "box-content",
    paddingBottom: theme.spacing(2),
  },
  container: {
    color: "#fff",
  },

  footerTop: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    borderBottom: "1px solid #fff",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2),

    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      borderBottom: "none",
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingBottom: theme.spacing(0),
    },
  },
  footerTopLogoLink: {
    flexShrink: 0,
    display: " flex",
    justifyContent: " center",
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(1),
      width: "100%",
    },
  },
  footerTopLogo: {
    height: "90px",
    weight: "90px",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },

  footerTopInfo: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(3),
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      paddingLeft: theme.spacing(0),
    },
  },

  footerTopTitle: {
    marginBottom: theme.spacing(1),
    color: "#fff",
    textAlign: "start",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  footerTopDesc: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      with: "100%",
    },
  },

  footerTopContact: {
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      marginBottom: theme.spacing(2),
    },
  },

  contactItem: {
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    "& span": {
      display: "block",
    },
    "&:not(:last-child)": {
      marginBottom: theme.spacing(1),
    },
  },

  contactIcon: {
    marginRight: theme.spacing(1.5),
  },

  footerTopPolicy: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(3),
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      paddingLeft: theme.spacing(0),
      borderTop: "1px solid #fff",
      borderBottom: "1px solid #fff",
    },
  },
  policyLink: {
    fontSize: "14px",
    "&:first-child": {
      marginBottom: theme.spacing(1),
    },
    "& a": {
      color: "white",
      "&:hover": {
        color: "#FCE313",
      },
      textDecoration: "none",
    },
  },

  footerBottom: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column-reverse",
      justifyContent: "center",
    },
  },
  copyright: {
    fontSize: "14px",
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(1),
    },
  },
  social: {
    display: "flex",
    alignItems: "center",
    color: "#1777F2",
    transition: "all .2s ease-in-out",
    "&:hover": {
      transform: "scale(1.05)",
    },
    fontSize: "24px",
  },
  linkCompany: {
    transition: "all .3s ease-in-out",
    "&:hover": {
      fontWeight: 600,
    },
  },
  footerSpan: {
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
}));

export default function Footer() {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Container className={classes.container}>
        <div className={classes.footerTop}>
          <div className={classes.footerTopLogoLink}>
            <Alink to="/home">
              <Image src={Logo} className={classes.footerTopLogo} />
            </Alink>
          </div>

          <div className={classes.footerTopInfo}>
            <Typography
              className={classes.footerTopTitle}
              align="center"
              variant="h5"
              component="h2"
              paragraph
            >
              WORLD IT DOJO
            </Typography>
            <div className={classes.footerTopContact}>
              <Typography className={classes.contactItem}>
                <FaMapMarker className={classes.contactIcon} />
                <span>
                  〒500-8856 <br></br>岐阜県岐阜市橋本町2-8 濃飛ニッセイビル6階
                </span>
              </Typography>

              <Typography className={classes.contactItem}>
                <FaPhone className={classes.contactIcon} />
                TEL 058-255-3066
              </Typography>

              <Typography className={classes.contactItem}>
                <FaFax className={classes.contactIcon} />
                FAX 058-255-3533
              </Typography>
            </div>
          </div>
          <div className={classes.footerTopPolicy}>
            <Typography className={classes.policyLink}>
              <Alink to="/terms-of-use">Điều khoản sử dụng</Alink>
            </Typography>

            <Typography className={classes.policyLink}>
              <Alink to="/security">Chính sách bảo mật thông tin</Alink>
            </Typography>
          </div>
        </div>

        <div className={classes.footerBottom}>
          <Typography className={classes.copyright} align="center">
            Copyright &copy; 2021 By &nbsp;
            <Link
              href="https://world-works.co.jp"
              rel="sponsored"
              title="株式会社ワールド"
              underline="none"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.linkCompany}
            >
              株式会社ワールド
            </Link>
            .&nbsp;
            <span className={classes.footerSpan}>All Rights Reserved.</span>
          </Typography>
          <Link
            href="https://www.facebook.com/world.it.dojo/"
            rel="sponsored"
            title="株式会社ワールド"
            underline="none"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.social}
          >
            <FaFacebookSquare />
          </Link>
        </div>
      </Container>
    </footer>
  );
}
