import React from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { navigate } from "@reach/router";
import { handlerAfterLoggedIn } from "../../../api/user.service";
import getFirebase from "../../../utils/firebase";

const firebase = getFirebase();

// Configure FirebaseUI.
const Login = () => {

  function getUiConfig() {
    return {
      signInFlow: "popup",
      signInSuccessUrl: "/",
      signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      ],
      callbacks: {
        signInSuccessWithAuthResult: () => {
          handlerAfterLoggedIn();
          navigate("/home");
        },
      },
    };
  }

  return (
    <React.Fragment>
      {firebase && (
        <StyledFirebaseAuth
          uiConfig={getUiConfig()}
          firebaseAuth={firebase.auth()}
        />
      )}
    </React.Fragment>
  );
};

export default Login;
